@import "bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "Pages/Home/Home.css";
@import "Pages/NC3/NC3.css";
@import "Pages/SpotifyCalculator/SpotCal.css";
@import "Components/Footer/Footer.css";

html, body, #root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  background-color: #242526;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  width: 0.65rem;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(150, 150, 150);
  border-radius: 30px;
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.PageContainer {
  width: 100%;
  background-color: inherit;
}

.Page {
  width: 100%;
  height: auto;
  overflow: visible;
}

.App-Name {
  font-size: calc(16px + 2vmin);
}

.NavBar {
  background-color: #111519;
}

.NavbarDropdown {
  font-size: calc(8px + 2vmin);
}

.NavbarDropdown-Header {
  font-size: calc(8px + 1vmin);
  color: azure !important;
}

.Link {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  height: 100%;
  width: 100%;
}

.Link:hover {
  color: azure;
}
