.Page_Home {
  margin: 10px;
  text-align: center;
}

.BackgroundImage {
  flex-grow: 1;
  /*background-image: url("https://cdn.novastudios.uk/public/Env_Factory_concept_detail.jpg");*/
}

.BodyText {
  color: white;
  font-size: calc(16px + 2vmin);
}

.MOTDText {
  color: white;
}

.ProductsList {
  color: white;
  list-style-position: inside;
}
