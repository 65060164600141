.Footer {
  background-color: #111519;
  color: white;
  min-height: 3.5rem;
  height: min-content;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
}

.FooterItem {
  margin: 0 10px;
}

.FooterLinksSection {

}

.FooterLinksContainer {
  display: flex;
  flex-direction: column;
}

.FooterLink {
  text-decoration: none;
}
