.Page_SpotCal {
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
}

.Page_SpotCal_Name {
  font-size: calc(40px + 2vmin);
}

.Page_SpotCal_Calculator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 2px solid #404040;
  background-color: #868b92;
}

.Page_SpotCal_Calculator_Bar {
  background-color: #242526;
  border: none;
}

.Page_SpotCal_Calculator_Text {
  padding-left: 5px;
  padding-right: 5px;
}

.Page_SpotCal_Calculator_Input {
  border: none;
  background-color: #2c2c2c;
}
