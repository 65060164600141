.Page_NC3 {

}

.DropdownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem;
}

.DropdownButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 100px;
  font-size: calc(20px + 2vmin);
  margin: 10px;
  width: 100%;
}

.DropdownButtonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.DropdownMenu {
  background-color: #212529;
  border-color: #212529;
  width: 100%;
}

.DropdownMenuItem {
  color: white !important;
}

.DropdownMenuItem:hover {
  background-color: #3c4146;
  border-color: #3c4146;
}

.ProductSection {
  height: 100%;
}

.ProductSectionInner {
  color: white;
}

.ProductName {
  font-size: calc(40px + 2vmin);
}

.SubHeader {
  font-size: calc(24px + 2vmin);
}

.ProductDescription {
  font-size: calc(16px + 2vmin);
}

.DownloadSection {
  width: 100%;
  /*height: 50rem;*/
  color: white;
  background: #2C2C2C;
  text-align: center;
}

.DownloadLinksContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 15px;
}

.ProductImage-Left {
  width: 75%;
  height: 75%;
  margin-bottom: 2%;
  float: left;
  border-radius: 10px;
  margin-right: 2%;
}

.ProductImage-Right {
  width: 75%;
  height: 75%;
  margin-bottom: 2%;
  float: right;
  border-radius: 10px;
}

.ProductImage-Header {
  font-size: calc(10px + 2vmin);
}

/*

.DownloadButton {
  background-color: #212529;
  border-color: #212529;
  width: 250px;
  height: 100px;
  font-size: calc(20px + 2vmin);
  margin: 10px;
}

.DownloadButton:hover {
  background-color: #3c4146;
  border-color: #3c4146;
}

*/

.DownloadButtonSubText {
  color: #BCBCBC;
  font-size: calc(4px + 1vmin);
}

